.errorUi {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1.5rem;
    max-width: 768px;
    margin: auto;
}

.heading {
    font-size: 2.6rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.message {
    color: var(--text-clr-2);
    line-height: 1.8;
}
