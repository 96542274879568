.fallbackLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--bg-clr);

    .loader {
        width: 120px;
    }
}
