@mixin media-breakpoint-xs {
    @media (max-width: 0) {
        @content;
    }
}

@mixin media-breakpoint-sm {
    @media (max-width: 576px) {
        @content;
    }
}

@mixin media-breakpoint-md {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin media-breakpoint-lg {
    @media (max-width: 992px) {
        @content;
    }
}

@mixin media-breakpoint-xl {
    @media (max-width: 1200px) {
        @content;
    }
}
