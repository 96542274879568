$primary: #f90d3b;
$secondary: #364f65;
$success: #1cc88a;
$warning: #f6c23e;
$danger: #e74a3b;
$info: #36b9cc;
$dark: #00264d;
$blue: #4286f4;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1900px,
);

:root {
    --primary: #{$primary};
    --secondary: #{$secondary};
    --success: #{$success};
    --warning: #{$warning};
    --danger: #{$danger};
    --info: #{$info};
    --dark: #{$dark};
    --blue: #{$blue};

    --sidebar-width: 220px;
    --topbar-height: 70px;

    --bg-clr: #f7fafc;
    --block-bg-clr: #fff;
    --text-clr-1: #413b3c;
    --text-clr-2: #698096;
    --muted: #c0cdd8;
    --border-clr: #e6e8ec;
}

.dark {
    // Dark Theme Variables
    --bg-clr: #17181a;
    --block-bg-clr: #292a2d;
    --text-clr-1: #b0b3b8;
    --text-clr-2: #{rgba(#b0b3b8, 0.7)};
    --muted: #4f565f;
    --border-clr: #353945;
}
