@import 'variables';

:root {
    --toastify-color-light: #fff;
    --toastify-color-dark: #23262f;
    --toastify-color-info: #{$info};
    --toastify-color-success: #{$success};
    --toastify-color-warning: #{$warning};
    --toastify-color-error: #{$danger};
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);

    --toastify-toast-width: 400px;

    --toastify-font-family: 'Inter', sans-serif;
    --toastify-z-index: 9999;

    --toastify-text-color-light: #413b3c;
    --toastify-text-color-dark: #8a8e91;
}

.Toastify__toast {
    position: relative;
    padding: 1.5rem 2.6rem;
    border-radius: 6px;
}
