@import 'styles/media';

.mainWrapper {
    min-height: 100vh;
    background-color: var(--bg-clr);
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    margin-left: var(--sidebar-width);
    min-height: 100vh;

    @include media-breakpoint-md() {
        margin-left: 0;
    }
}
