@import 'styles/variables';
@import '~bootstrap/scss/bootstrap.scss';
@import 'react-toastify/dist/ReactToastify.css';
@import 'styles/toastify-overrides';

body {
    font-family: 'Inter', sans-serif;
    color: var(--text-clr-1);
}

.main-content {
    padding: 1.5rem;
    padding-top: calc(70px + 1.5rem);
}

.page-title {
    display: flex;
    align-items: center;
    color: var(--text-clr-2);
    font-size: 1.6rem;
    font-weight: 300;
    margin-bottom: 1.8rem;
}

.card {
    border: none;
    border-radius: 8px;
    background-color: var(--block-bg-clr);

    .card-body {
        padding: 1.5rem;
    }
}

.app-table {
    width: 100%;
    font-size: 0.9rem;

    thead tr {
        border-bottom: 1px solid var(--border-clr);
    }

    th {
        font-weight: 700;
        padding: 1rem 1rem;
    }

    tbody tr {
        border-bottom: 1px solid var(--border-clr);

        &:last-child {
            border-bottom: none;
        }

        // &:hover {
        //     background-color: rgba($blue, 0.1);
        //     cursor: pointer;
        // }
    }

    td {
        padding: 1.2rem 1rem;
        vertical-align: middle;
    }
}

.table-img {
    height: 48px;
    aspect-ratio: 4/3;
    border-radius: 2px;
    margin-right: 1rem;
    background-color: var(--bg-clr);
}

.table-action-link {
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    color: $blue;
    white-space: nowrap;

    &:hover {
        color: $blue;
    }
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-error {
    margin-top: 0.4rem;
    color: var(--danger);
    font-size: 0.8rem;
}

.input-label {
    font-size: 0.85rem;
    color: var(--text-clr-2);
    margin-bottom: 0;
}

.input-text-field {
    color: var(--text-clr-1);
    width: 100%;
    background: transparent;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid var(--border-clr);
    border-radius: 0;
    padding: 10px 0;
    outline: none;

    &:focus {
        border-color: var(--primary);
    }

    &[readonly] {
        background-color: var(--bg-clr);
    }

    &::placeholder {
        color: var(--muted);
    }
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.375rem 1.5rem;
}

.btn-primary,
.btn-success,
.btn-info,
.btn-warning {
    color: #fff !important;
}
